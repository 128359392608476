import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { Container, Menu, MenuItem } from '@material-ui/core';
import { Add, Autorenew, Edit, Sort, Link } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { SectionBar } from '../../componentsUI/SectionBar';
import { isEmpty } from '../../utils/ObjectUtils';
import { PageListContent } from '../Common/styled/PageContent';
import { PatientMedicalCasesCollapse } from './PatientMedicalCasesCollapse';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import Loading from '../Common/Loading';
import { GET_AUTHORIZED_PATIENTS_FOR_PREVIEW } from '../../graphql/queries';
import TableCollapse from '../../componentsUI/tableCollapse';
import { GetFormat } from '../../utils/functions';
import { NewPatientDialog } from './modal/PatientNewDialog';
import { DeletePatientDialog } from './modal/DeletePatientDialog';
import { InvitePatientDialog } from './modal/InvitePatientDialog';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ContainerUI } from '../Common/styled/ContainerUI';
import { graphQLErrorParse } from '../../utils/ErrorGraphQLUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

const fieldNames = [
  { label: 'patient.id', id: 'node.customId', width: 170, field: 'PATIENT_ID', direction: 'ASC' },
  { label: 'name', id: 'node.name', width: 120, field: 'NAME', direction: 'ASC' },
  { label: 'surname', id: 'node.surname', width: 150, field: 'SURNAME', direction: 'ASC' },
  { label: 'last.modified.index', id: 'node.createdAt', width: 105, format: 'DATEFORMAT', align: 'center' },
];

export const PatientsView = ({ hospitalUuid }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState([]);
  const [graphResetStore, setGraphResetStore] = useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [refreshNewModal, setRefreshNewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const sortData = {
    default: { field: 'NAME', direction: 'ASC' },
    fields: [{ key: 'NAME', text: 'Name' }, { key: 'SURNAME', text: 'Surname' }, { key: 'PATIENT_ID', text: 'Patient ID' }],
  };
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);

  // TODO: find out why this sometimes return network error in store
  const { called, loading, data, error, refetch, fetchMore, client } = useQuery(
    GET_AUTHORIZED_PATIENTS_FOR_PREVIEW, {
      variables: {
        orderBy: { field: orderByField, direction: orderByDirection },
        hospitalUuid,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const patients = data && data.authorizedPatients;

  if (called && !loading && !patients && !graphResetStore) {
    client.resetStore().then(() => refetch());
    setGraphResetStore(true);
  }

  const reverseDirection = () => (orderByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      refetch();
      setAnchorEl();
      setSelected([]);
    }
  };

  const fetchMorePatients = (fetchMoreCb) => {
    const { endCursor } = patients.pageInfo;

    fetchMore({
      query: GET_AUTHORIZED_PATIENTS_FOR_PREVIEW,
      variables: {
        cursor: endCursor,
        orderBy: { field: orderByField, direction: orderByDirection },
        hospitalUuid,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.authorizedPatients.edges;

        return newEdges.length ? {
          authorizedPatients: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult.authorizedPatients.__typename,
            totalCount: previousResult.authorizedPatients.totalCount,
            pageInfo: fetchMoreResult.authorizedPatients.pageInfo,
            edges: [...previousResult.authorizedPatients.edges, ...newEdges],
          },
        } : previousResult;
      },
    })
      .then(({ data: fetchMoreData }) => (
        fetchMoreData.authorizedPatients.pageInfo.hasNextPage && fetchMoreCb()
      ));
  };

  const getTitle = (item) => (
    `${item.node.name} ${item.node.surname} - ${GetFormat(item.node.customId)}`
  );

  const getSubTitle = (item) => (
    `${GetFormat(item.node.birthDate, 'DATEFORMAT')}`
  );

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexPatient = patients && patients.edges[index].node;

    navigate(`/patient/${indexPatient.uuid}`);
  };

  const handleAddedNewPatient = () => {
    setShowAddNewModal(false);
    setRefreshNewModal(true);
    setTimeout(() => setRefreshNewModal(false), 250);
    refetch();
  };

  const handleCloseAddNew = () => {
    setShowAddNewModal(false);
  };

  const handleCloseDelete = () => {
    setSelected([]);
    setShowDeleteModal(false);
    refetch();
  };

  const handleCloseInvite = () => {
    setShowInviteModal(false);
  };

  const handleRefresh = () => {
    client.resetStore().then(() => refetch());
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const patientsCountMessage = patients && patients.totalCount
    && `${patients.totalCount} ${t('registries').toLowerCase()}`;

  const patient = (patients && selected.length > 0) && patients.edges[selected[0]].node;

  const buttons = [
    { name: 'new.patient', icon: Add, handleClick: () => navigate('/patients/new'), disabled: false },
    // { name: 'new.patient', icon: Add, handleClick: () => setShowAddNewModal(true), disabled: false },
    { name: 'edit.patient', icon: Edit, I: 'edit', data: patient, handleClick: () => navigate(`/patient/edit/${patient.uuid}`), disabled: selected.length !== 1 },
    { name: 'delete.patient', icon: DeleteIcon, I: 'edit', data: patient, handleClick: () => setShowDeleteModal(true), disabled: selected.length === 0 },
    { name: 'invite.patient', icon: Link, I: 'view', data: patient, handleClick: () => setShowInviteModal(true), disabled: selected.length === 0 },
    { name: 'divider2', type: 'divider' },
    { name: 'refresh', icon: Autorenew, handleClick: handleRefresh, disabled: false },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder, disabled: false },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDownIcon className={className} /> : <ArrowDropUpIcon className={className} />
  );

  const errorMessage = graphQLErrorParse(error);

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  if (refreshNewModal) setTimeout(() => setRefreshNewModal(false), 250);

  return (
    <ContainerUI>
      <Navbar>
        {!refreshNewModal && (
          <NewPatientDialog open={showAddNewModal} onClose={handleCloseAddNew} onCreated={handleAddedNewPatient} />
        )}
        <DeletePatientDialog open={showDeleteModal} onClose={handleCloseDelete} patient={patient} />
        <InvitePatientDialog open={showInviteModal} onClose={handleCloseInvite} patient={patient} />

        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item, index) => (
            <MenuItem
              key={index.toString()}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>

        <SectionBar title="patients" subtitle={patientsCountMessage} items={buttons} />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <PageListContent>
          {loading && isEmpty(patients) ? <Loading /> : (
            <CardWrapperUI>
              {patients
                && (
                  <TableCollapse
                    fieldNames={fieldNames}
                    items={patients.edges}
                    GetTitle={getTitle}
                    GetSubtitle={getSubTitle}
                    GetCollapse={PatientMedicalCasesCollapse}
                    selected={selected}
                    setSelected={setSelected}
                    handleGoto={handleGoto}
                    isMore={patients
                      && patients.pageInfo
                      && patients.pageInfo.hasNextPage}
                    fetchMoreFn={fetchMorePatients}
                    order={{ field: orderByField, direction: orderByDirection }}
                    handleOrder={handleOrderBy}
                  />
                )}
            </CardWrapperUI>
          )}
        </PageListContent>
      </Container>
    </ContainerUI>
  );
};
