import React from 'react';
import { useSelector } from 'react-redux';

import Layout from '../../components/MenuLayout';
import Loading from '../../components/Common/Loading';
import { PatientsView } from '../../components/Patient/PatientsView';

export default () => {
  const hospitalUuid = useSelector((state) => state.hospital && state.hospital.uuid);
  return (
    <Layout>
      {(hospitalUuid && typeof hospitalUuid === 'string')
        ? <PatientsView hospitalUuid={hospitalUuid} />
        : <Loading />}
    </Layout>
  );
};
